.home-container{
    .actions{
        text-align: right;
        &__btn-add{
            border-radius: 5px;
        }
        margin-bottom: 10px;
    }
    .table-custom{
        box-shadow: 0 0 5px #b9b1b1;
    }
}

.home-detail-container{
    .actions{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
}