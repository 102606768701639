.app-detail-container {
    width: 50%;
}

.user-container {
    .customer-list {
        display: flex;
        flex-wrap: wrap;
        .customer-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 200px;
            border-radius: 20px;
            box-shadow: 0px 0 5px #0000004f;
            padding: 10px;
            margin: 0 10px;
            &:hover{
                cursor: pointer;
                box-shadow: 0px 0 10px #0000004f;

            }
        }
    }
}
