.form-login{
    width: 30%;
    /* margin: 0 auto; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 20px !important;
    /* border: 1px solid; */
    box-shadow: 0 0 10px 0px #bdbdbd;
}